
import React from 'react'
import Layout from "../components/Layout"
import { isIOS, isBrowser, isMobile, isAndroid} from 'react-device-detect'
import { Link } from 'gatsby'
import { render } from 'react-dom'

const index = () => {
  if (isIOS) { return (
    <Layout>
    <section>
      <div className="hero-content">
        <h1 >CXMOBL IOS Download</h1>
        <a className="cxmobl-button" href="itms-services://?action=download-manifest&url=https://cxwebappeast.blob.core.windows.net/cxwebapp-cxmobl/manifest.plist">CXMOBL Download</a>
        <p className="under-download">Open your downloads and tap cxmobl.ipa to install the app.</p>
        <p>Open your phone's Settings. If there is an option to search, search for "Unknown sources" and tap on the result. If not, look for the Security option (depending on your device). Check the Unknown Sources option and tap OK.</p>
        <p>Click here to download the app</p>
      </div>
    </section>
  </Layout>
  )
} if (isAndroid) {
 return (
  <Layout>
    <section>
      <div className="hero-content">
        <h1 >CXMOBL Android Download</h1>
        <a className="cxmobl-button" href="https://cxwebappeast.blob.core.windows.net/cxwebapp-cxmobl/cxmobl-app-release.apk">CXMobl Download</a>
        <p className="under-download">Open your downloads and tap cxmobl-app-release.apk to install the app.</p>
        <p>Open your phone's Settings. If there is an option to search, search for "Unknown sources" and tap on the result. If not, look for the Security option (depending on your device). Check the Unknown Sources option and tap OK.</p>
        <p>Click here to download the app</p>
      </div>
    </section>
  </Layout>
 
  )
 }
 else { 
   return (
    <Layout>
    <section>
      <div className="hero-content">
        <h1 >CXMOBL Download</h1>
        <p>Please open this page on your mobile device to install CXMOBL.</p>
        
      </div>
    </section>
  </Layout>
   )

 }

}


export default index